$(document).ready(function() {
    /**
     * IE support for object-fit
     */
    objectFitImages();

    /**
     * @return {Boolean} If the current view is smaller than 668px
     */
    function isMobile() {
      return window.matchMedia('(max-width: 668px)').matches;
    }

    var image = document.getElementsByClassName('parallaxImage');
    new simpleParallax(image, {
      orientation: 'down',
      delay: 0,
      customWrapper: '.parallax-container',
      scale: 1.3
    });

    function Utils() {

    }

    Utils.prototype = {
        constructor: Utils,
        isElementInView: function (element, fullyInView) {
            var pageTop = $(window).scrollTop();
            var pageBottom = pageTop + $(window).height();
            var elementTop = $(element).offset().top;
            var elementBottom = elementTop + $(element).height();

            if (fullyInView === true) {
                return ((pageTop < elementTop) && (pageBottom > elementBottom));
            } else {
                return ((elementTop <= pageBottom - 300) && (elementBottom >= pageTop));
            }
        }
    };

    var Utils = new Utils();

    var tick = false;  // Track whether call is currently in process

    function checkActiveMenu () {
      if ($("#navigationWrapper").length > 0) {
        var $listItems = $("#navigationWrapper").find("li");
        var currentElement = "";
        var id = null;

        /**
         * Add more elements here, in correct order!
         */
        if (Utils.isElementInView($('#menu'), false)) {
            currentElement = "#menu";
        }
        if (Utils.isElementInView($('#opening-hours'), false)) {
          currentElement = "#opening-hours";
        }
        if (Utils.isElementInView($('#contact'), false)) {
          currentElement = "#contact";
        }

        $listItems.removeClass("active");
        if (currentElement != "") {
          $( 'a[href="' + currentElement + '"]' ).closest('li').addClass('active');
        }
      }
    }

    $(document).on("scroll",function(){
      if (!tick) {
        setTimeout(function () {
          checkActiveMenu();
          tick = false;
        }, 50)
      }
      tick = true;
    });

    var navigationWrapper = document.getElementById('navigationWrapper');
    var modalNavigationWrapper = document.getElementById('modalNavigationWrapper');
    $('a[href*="#"]')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function(event) {

          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          history.replaceState(null, null, document.location.pathname + '#' + this.hash.slice(1));

          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            var sc = target.offset().top - 60;

            if (isMobile()) {
                document.getElementById('hamburger').classList.toggle('is-active');
                navigationWrapper.classList.toggle('modal-navigation-open');
                closeModalNavigation();
            }

            $('html, body').animate({
              scrollTop: sc
            }, 1000, function() {
            });
          }
        // }

      });

      /**
       * Navigation
       */
      const closeModalNavigation = () => {

        navigationWrapper.classList.add('modal-navigation-closing');
        modalNavigationWrapper.classList.add('modal-navigation-closing');

        // Enable scroll on body/html tags again
        document.getElementsByTagName("BODY")[0].style.overflowY = 'auto';
        document.getElementsByTagName("HTML")[0].style.overflowY = 'auto';

        setTimeout(() => {
            // Remove closing animations and hide modal
            modalNavigationWrapper.classList.remove('modal-navigation-closing');
            modalNavigationWrapper.classList.remove('display-modal')
            navigationWrapper.classList.remove('modal-navigation-closing');
        }, 200);
      };

      const hamburgerClickHandler = (event) => {
        event.preventDefault();
        document.getElementById('hamburger').classList.toggle('is-active');
        // Set modal open state on the navigation wrapper
        navigationWrapper.classList.toggle('modal-navigation-open');

        if (navigationWrapper.className.indexOf('modal-navigation-open') > -1) {
            // Open the navigation modal
            modalNavigationWrapper.classList.add('display-modal');

            // Disable scroll on body/html tags
            document.getElementsByTagName("BODY")[0].style.overflowY = 'hidden';
            document.getElementsByTagName("HTML")[0].style.overflowY = 'hidden';
        }
        else {
            // Add closing animation
            console.log('he?')
            closeModalNavigation();
        }
      };

      // Add event listener on hamburger click
      document.getElementById('hamburger')
        .addEventListener('click', hamburgerClickHandler);
});